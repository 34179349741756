// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// Steve: commented out next line when removing turbolinks: https://stackoverflow.com/a/61005811/5783745
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")










window.$ = window.jQuery = require("jquery");
import "bootstrap/dist/js/bootstrap.bundle"



// Getting css through webpacker

import('styles/application.scss');

//import("bootstrap/dist/css/bootstrap"); //Confirmed to work, but has Flash of Unstyled Content: https://stackoverflow.com/a/56060032/5783745
// So, based on: https://rossta.net/blog/webpacker-with-bootstrap.html
import 'styles/steve_bootstrap' // this is scss






// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)


